import React from "react";
import PropTypes from "prop-types";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <h3 className="text-secondary">Oh no! Something went wrong...</h3>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary} className="btn btn-primary">
        Go Home
      </button>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default ErrorFallback;
